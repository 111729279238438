<template>
  <div class="add-comment pt-4 mt-4">
    <div class="container">
      <div class="row">
        <div class="col-12 comment-form">
          <text-area-input label="دیدگاه شما" rows="10" col="12" id="name" v-model="form.txt"></text-area-input>
        </div>
        <div class="col-12 comment-form">
          <text-input label="نام و نام خانوادگی" type="text" id="name" v-model="form.name"></text-input>
        </div>
        <div class="col-12 comment-form">
          <text-input label="شماره تماس " type="text" id="name" v-model="form.tnumber"></text-input>
        </div>
        <div class="col-12 d-flex comment-form">
          <div class="col-6">
            <text-input label="کد ارسالی " type="text" id="name" v-model="form.code"></text-input>
          </div>
          <div class="col-6 d-flex justify-content-start">
            <div class="col-3">
              <button type="button" v-on:click="sendCode()" :disabled="counting" name="sendCode" class="btn btn-outline-primary mt-2">
                <countdown v-if="counting" :time="120000" @end="handleCountdownEnd">
                  <template slot-scope="props">ارسال مجدد کد در {{ props.totalSeconds }} ثانیه دیگر</template>
                </countdown>
                <span v-else>ارسال کد</span>
              </button>
            </div>
            <div class="col-9" v-if="error">
              <label v-for="(item,field) in error" :key="field" class="mr-2 text-danger d-block">{{ item[0] }}</label>
            </div>
            <label class=" mr-2 text-info" v-if="!doneFrm">جهت ارسال کد تایید به شماره موبایل شما و ثبت نظر اقدام نمایید</label>
            <label class=" mr-2 text-success" v-if="success">{{ successMesage }}</label>
          </div>
        </div>
        <div class="col-12">
            <transition name="slide-fade" mode="in-out">
            <b-alert variant="danger" show v-if="frmErrors">
              <span>خطا !</span>

                <p v-for="(error,field) in frmErrors" :key="field">{{ error[0]}}</p>

            </b-alert>
            </transition>
            <transition name="slide-fade" mode="in-out">
            <b-alert variant="success" show v-if="frmSuccess">
              <span>با تشکر</span>
              <p>{{ frmSuccessMesage }}</p>
            </b-alert>
            </transition>
        </div>
        <div class="col-12 pt-3 comment-form d-flex justify-content-end">
          <button type="button" :disabled="disable" class="btn px-5 py-2 btn-primary btn-large btn-submit" v-on:click="submitFrm()" name="submit">ثبت دیدگاه</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from './inputs/TextInput';
import TextAreaInput from './inputs/TextAreaInput';
import HttpClient from '@/services/http.service'
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
  name:'AddComment',
  components: {
    TextInput,
    TextAreaInput,
    'countdown':VueCountdown,
  },
  props:{
    postId:Number
  },
  data(){
    return {
      form:{
        name:new String(),
        tnumber:new String(),
        code:new String(),
        txt:new String(),
      },
      error:null,
      success:false,
      successMesage:null,
      counting: false,
      frmErrors : null,
      frmSuccess:false,
      frmSuccessMesage:null,
      disable:false,
      doneFrm:null,

    };
  },
  methods: {
    async sendCode() {
      try {
        let {status,data} = await HttpClient.post('/fa/comment/send-code',this.form);
        if (status == 200) {
          this.success = true;
          this.successMesage = data;
          this.counting = true;
          this.error = null;
          this.frmErrors = null;
          this.doneFrm = true;
        }
      } catch (e) {
        this.error = e.response.data.errors;
        this.doneFrm = true;
      }
    },
    handleCountdownEnd(){
      this.counting = false;
    },

    async submitFrm(){
      try {
          let{status,data} = await HttpClient.post(`/fa/comment/create?pid=${this.postId}`,this.form);
          if (status == 200) {
            this.frmSuccess = true;
            this.frmSuccessMesage = data;
            this.disable = true;
            this.error = null;
            this.frmErrors = null;
            this.doneFrm=true;
          }
      } catch (e) {
        this.frmErrors = e.response.data.errors;
        this.doneFrm = true;
      }
    }
  }

}
</script>

<style lang="css" scoped>
.comment-form{
  border-top: 2px solid ;
  border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  border-image-slice: 1;
}
.frm-comment{
  width: 100%;
  border-bottom: 2px solid;
  border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  border-image-slice: 1;

}
.btn-submit{
  background: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  font-size: 20px;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
  transform: translateY(40px);
  opacity: 0;
}
</style>
